import React, { useRef } from "react";
import Carousel from "react-elastic-carousel";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";

const renderQuote = (name, place, text) => (
  <Card style={{ borderColor: "transparent", height: "100%" }}>
    <Card.Body className="p-5">
      <div className="d-flex justify-content-center mb-4">
        <FontAwesomeIcon icon={faQuoteRight} />
      </div>
      <figure className="text-center mb-0">
        <blockquote className="blockquote mb-4">
          <p>{text}</p>
        </blockquote>
        <figcaption className="blockquote-footer mb-0">
          <div>{place}</div>
        </figcaption>
      </figure>
    </Card.Body>
  </Card>
);

function QuoteCarouselTeacher() {
  const carouselRef = useRef(null);

  const infiniteScroll = ({ index }) => {
    if (index >= 3) carouselRef.current.goTo(0);
  };

  return (
    <Carousel
      itemsToShow={1}
      pagination={false}
      ref={carouselRef}
      onNextEnd={infiniteScroll}
    >
      {renderQuote(
        "",
        "Elizabeth, High School ELA Teacher, Ohio",
        "I LOVE IT. It breaks the paper writing process into such manageable chunks and helps students efficiently organize and synthesize information from multiple sources! Plus, the fact that it’s free is AMAZING."
      )}
      {renderQuote(
        "",
        "Matt, High School ELA Teacher, Ohio",
        "Opendemia is an excellent resource for writers. It helps students organize their research and seamlessly include that research in their essays. I recommend it to my CCP classes each semester."
      )}
      {renderQuote(
        "",
        "Adam, High School ELA Teacher, Ohio",
        "Opendemia is awesome! My students are going to love this, and it saves me a headache!"
      )}
      {renderQuote(
        "",
        "Elizabeth, High School ELA Teacher, Ohio",
        "I LOVE IT. It breaks the paper writing process into such manageable chunks and helps students efficiently organize and synthesize information from multiple sources! Plus, the fact that it’s free is AMAZING."
      )}
      {/* {renderQuote(
        "",
        "ELA Teacher in Ohio",
        "This is awesome! My students are going to love this, and it saves me a headache!"
      )} */}
    </Carousel>
  );
}

export default QuoteCarouselTeacher;
