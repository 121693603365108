import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

function SEO({ title, description, keywords }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
      ].concat(
        keywords.length > 0
          ? {
            name: 'keywords',
            content: keywords.join(', '),
          }
          : [],
      )}
    />
  );
}

export default SEO;

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.array,
};
