import React from "react";
import "../../CSS/LandingPageView.css";
import {
  ResponsiveEmbed,
  Button,
  Row,
  Col,
  Container,
  NavLink,
} from "react-bootstrap";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import { Redirect, Link, withRouter } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import Footer from "../LandingPage/Footer";
import QuoteCarouselTeacher from "./QuoteCarouselTeacher";
import FeatureListAltContentTeacher from "./FeatureListAltContentTeacher";
import QuestionsViewTeacher from "./QuestionsViewTeacher";
import bolerLogo from "../../Assets/Teacher/boler.png";
import delLogo from "../../Assets/Teacher/del.png";
import tolLogo from "../../Assets/Teacher/tol.png";
import FolderSVG from "../../Assets/LandingPage/group.png";

class TeacherView extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  goToFAQ = () => {
    this.props.history.push("/FAQ");
  };

  goToLogin = () => {
    this.props.history.push("/classroom");
  };

  goToContact = () => {
    this.props.history.push("/contact");
  };

  iconBullet = (text) => (
    <p
      className="d-flex justify-content-left w-100 hero-bullet-text text-color-darkmode opendemiaBodyFont"
      style={{ marginBottom: "5px" }}
    >
      {" "}
      <FontAwesomeIcon
        size="2x"
        icon={faCheckCircle}
        className="checkboxMobileStyling"
      />{" "}
      <span
        className=""
        style={{ fontSize: "large", textAlign: "left", paddingLeft: "5px" }}
      >
        {text}
      </span>
    </p>
  );

  landingThree = () => (
    <React.Fragment>
      <div className="styleDiv">
        <Container>
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#41a047" fill-opacity="1" d="M0,224L1440,128L1440,0L0,0Z"></path></svg> */}
          <Row style={{ paddingTop: "35px" }}>
            {/* <Col sm={1}></Col> */}
            <Col className="align-items-left" xs={12} sm={7}>
              <div style={{ paddingTop: "2ex" }}>
                <h1 className="font-weight-bold titleFontSize text-left w-100 text-color-darkmode opendemiaLogoFont">
                  Research &#38; Writing Simplified
                </h1>
              </div>
              <div style={{}}>
                <h3 className="text-left w-100 mb-3 text-color-darkmode font-weight-light opendemiaBodyFont">
                  Step-by-step assistance that empowers students to focus on
                  synthesizing and writing
                </h3>
              </div>

              {/* <Row xs={12}>
                <Col md={12}>
                  <p className="text-muted">
                    We surveyed over 1,500 students and a resounding 86% shared
                    that they hate, or get anxiety about writing papers, so
                    Opendemia.com was designed by students &amp; teachers to
                    help with this problem.
                  </p>
                </Col>
              </Row> */}

              {!this.props.signedIn ? (
                <>
                  <Button
                    className="landerTopCreateFreeAccount mt-4 btn-block createaccountButton"
                    onClick={this.goToFAQ}
                    style={{ borderRadius: "15px", marginLeft: "auto" }}
                  >
                    {this.props.signedIn ? (
                      <span>Student Tutorial</span>
                    ) : (
                      <span>Student Tutorial</span>
                    )}
                  </Button>
                </>
              ) : (
                <Row>
                  <Button
                    className="landerTopCreateFreeAccount mt-4 btn-block createaccountButton"
                    onClick={this.goToFAQ}
                    style={{ borderRadius: "15px", marginLeft: "auto" }}
                  >
                    <span>Student Tutorial</span>
                  </Button>
                </Row>
              )}
            </Col>
            <Col
              xs={12}
              sm={5}
              className="pl-2 pr-2 d-flex justify-content-center"
            >
              <div
                className="text-center"
                style={{
                  backgroundColor: "whitesmoke",
                  borderRadius: "50px",
                  marginTop: "20px",
                }}
              >
                <p className="textSpacingLanding lead mt-5">
                  University Partners
                </p>
                <a
                  className="pr-2"
                  // href="https://www.linkedin.com/company/opendemia/about/"
                  // target="_blank"
                  // rel="noreferrer"
                >
                  <LazyLoadImage width="160" alt="toledo" src={tolLogo} />
                </a>
                <a
                  className="pr-2 pl-2"
                  // href="https://www.facebook.com/opendemia"
                  // target="_blank"
                  // rel="noreferrer"
                >
                  <LazyLoadImage width="160" alt="bolder" src={bolerLogo} />
                </a>

                <a
                  className="pr-2 pl-2"
                  // href="https://www.instagram.com/opendemia"
                  // target="_blank"
                  // rel="noreferrer"
                >
                  <LazyLoadImage
                    style={{ marginTop: "30px", marginBottom: "30px" }}
                    width="160"
                    alt="delaware"
                    src={delLogo}
                  />
                </a>
              </div>
            </Col>
            {/* <Col sm={1}></Col> */}
          </Row>
        </Container>
      </div>

      <Container>
        {/* <img
          src={HeroSVG}
          style={{
            width: '70%', marginBottom: '3ex', display: 'block', maxHeight: '200px', marginRight: 'auto', marginLeft: 'auto',
          }}
          alt="Organize your bibliography"
        /> */}
        <QuoteCarouselTeacher
          className="pt-4"
          style={{ backgroundColor: "whitesmoke" }}
        />
        <hr style={{ width: "45%" }} />
        <div className="pb-2">
          <Container>
            <Row className="pt-4 align-items-center justify-content-center">
              <Col xs={12} className="mb-md-2 text-center ">
                <h6 className="lead">University Library Recommendations</h6>
              </Col>
              <Col
                xs={6}
                lg={4}
                className="text-center"
                style={{ padding: "0px" }}
              >
                <p className="lead text-muted" style={{ fontSize: "1rem" }}>
                  <a
                    className="pr-2 mt-2"
                    href="https://libguides.library.ohio.edu/citation/builders-tools"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline", color: "black" }}
                  >
                    Ohio University
                  </a>
                </p>
              </Col>
              <Col
                xs={6}
                lg={4}
                className="text-center"
                style={{ padding: "0px" }}
              >
                <p className="lead text-muted" style={{ fontSize: "1rem" }}>
                  <a
                    className="pr-2 mt-2"
                    href="https://lib.guides.umd.edu/c.php?g=326821&p=2194178"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline", color: "black" }}
                  >
                    University of Maryland
                  </a>
                </p>
              </Col>
              <Col
                xs={6}
                lg={4}
                className="text-center"
                style={{ padding: "0px" }}
              >
                <p className="lead text-muted" style={{ fontSize: "1rem" }}>
                  <a
                    className="pr-2 mt-2"
                    href="https://library.cscc.edu/mla9/more"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline", color: "black" }}
                  >
                    Columbus State Community College
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <hr style={{ width: "45%" }} />

        {/* <div className="pt-4 pb-2">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col xs={12} className="mb-md-2 text-center">
                <h6>University Partnerships</h6>
              </Col>

              <Col xs={12} md={12} className="">
                <div className="text-center">
                  <a
                    className="pr-2 mt-2"
                    // href="https://www.linkedin.com/company/opendemia/about/"
                    // target="_blank"
                    // rel="noreferrer"
                  >
                    <LazyLoadImage width="160" alt="toledo" src={tolLogo} />
                  </a>
                  <a
                    className="pr-2 pl-2 mt-2"
                    // href="https://www.facebook.com/opendemia"
                    // target="_blank"
                    // rel="noreferrer"
                  >
                    <LazyLoadImage width="160" alt="bolder" src={bolerLogo} />
                  </a>

                  <a
                    className="pr-2 pl-2 mt-2"
                    // href="https://www.instagram.com/opendemia"
                    // target="_blank"
                    // rel="noreferrer"
                  >
                    <LazyLoadImage width="160" alt="delaware" src={delLogo} />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}

        <FeatureListAltContentTeacher />
        <QuestionsViewTeacher />

        <div className="firstInfoBlock">
          <Container>
            <Row className="rowSpacingLanding">
              <Col sm={12} md={6}>
                <img
                  src={FolderSVG}
                  style={{ width: "80%", marginBottom: "2ex" }}
                  alt="Organize your bibliography"
                />
              </Col>
              <Col sm={12} md={6} className="align-self-center">
                <div>
                  <p className="h2"> Our Mission</p>
                  {/* <FontAwesomeIcon icon={faFolderOpen} /> */}
                  <p className="textSpacingLanding lead">
                    We named the company Opendemia because the whole goal is to
                    Open-up-academia. Allowing millions of high school and
                    college students to become empowered rather than discouraged
                    by research projects every year.{" "}
                    <p>Please join us on our mission!</p>
                  </p>{" "}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <div className="pt-4 pb-2">
        <Container>
          <Row className="altColorLanding align-items-center justify-content-center">
            <Col xs={12} className="mb-md-2 text-center">
              <h6>
                We named the company Opendemia because the whole goal is to
                Open-up-academia. Allowing millions of high school and college
                students to become empowered rather than discouraged by research
                projects every year. <p>Please join us on our mission!</p>
              </h6>
            </Col>
          </Row>
        </Container>
      </div> */}

        <Container className="pb-4">
          <Col className="align-items-center">
            <Row
              xs={12}
              className="text-center d-md-flex justify-content-center align-items-center"
            >
              <h2 className="">Ready To Start?</h2>
            </Row>
            <Row lg={12} sm={12} className="pt-4">
              <Button className="buttonBox2 btn-block" onClick={this.goToLogin}>
                <span>Create Free Account</span>
              </Button>
            </Row>
            <Row lg={12} sm={12} className="pt-4">
              <Button className="buttonBox2 btn-block" onClick={this.goToFAQ}>
                <span>Student Tutorial</span>
              </Button>
            </Row>
            <Row lg={12} sm={12} className="pt-4">
              <Col xs={12} className="mb-md-2 text-center">
                <h6>
                  Not sure yet?{" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    onClick={this.goToContact}
                  >
                    <span>Contact Us</span>
                  </a>{" "}
                  to schedule a demo with our CEO
                </h6>
              </Col>
            </Row>
          </Col>
        </Container>
      </Container>
      <Footer />
    </React.Fragment>
  );

  render() {
    return this.landingThree();
  }
}

export default withRouter(TeacherView);
