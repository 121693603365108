import React from 'react';
import {
  Row,
  Col,
  Container,
} from 'react-bootstrap';

const YoutubePanel = (props) => (
  <div className="firstInfoBlock">
    <Container>

      <Row className="rowSpacingLanding">
        <Col sm={12} md={6} className="align-self-center">
          <div>
            <p className="h2"> Not Convinced Yet?</p>
            {/* <FontAwesomeIcon icon={faFolderOpen} /> */}

            <p className="textSpacingLanding lead">

              Watch our 2 minute overview video
            </p>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <props.yt />
        </Col>

      </Row>

    </Container>

  </div>

);

export default YoutubePanel;
