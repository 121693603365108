import { combineReducers } from "redux";
import Axios from "axios";
import { LOGIN, CLASSES, SET_CLASSES } from "../actions/index";
import { baseURL } from "../../Constants";

const initialState = {
  bio: "",
  firstName: "",
  imageLocation: "",
  jobTitle: "",
  lastName: "",
  university: "",
  userName: "",
  adminUserName: "",
  personalEmail: "",
  major: "",
  classes: [],
  loginEmail: "",
  schoolEmail: "",
  paid: false,
  cancelled: false,
};

export const loginInfo = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      if (state.classes == undefined) {
        return { ...action.payload, classes: [] };
      }
      return { ...action.payload };
    }
    case CLASSES: {
      const { collegeId } = action.payload.classes;
      const { courseId } = action.payload.classes;

      if (
        state.classes.findIndex(
          (c) => c.courseId === courseId && c.collegeId === collegeId
        ) > -1
      ) {
        alert(`Class ${collegeId} ${courseId} already exists!`);
      } else {
        state.classes.push(action.payload.classes);
        Axios.put(
          `${baseURL}/profiles/classes/${state.userName}`,
          state.classes
        );
      }

      return { ...state };
    }
    case SET_CLASSES: {
      const classes = [];
      action.payload.classes.forEach((c, i) => {
        if (
          action.payload.classes.findIndex(
            (t) =>
              t.courseId === c.courseId &&
              t.collegeId === c.collegeId &&
              t.semester === c.semester
          ) === i
        ) {
          classes.push(c);
        }
      });
      state.classes = [...classes];
      Axios.put(`${baseURL}/profiles/classes/${state.userName}`, state.classes);

      return { ...state };
    }
    default:
      return state;
  }
};

export default combineReducers({ loginInfo });
