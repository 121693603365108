import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import { NavLink, Link } from "react-router-dom"; // this is the same as react router
import fblogo from "../../Assets/SocialLogos/whitefb.png";
import linkedInLogo from "../../Assets/SocialLogos/whitelinkedin.png";
import igLogo from "../../Assets/SocialLogos/whiteig.svg";

const Footer = (props) => (
  <div
    className="footerStyling"
    style={{
      backgroundColor: "var(--opendemia-landing-grey)",
      maxWidth: "100%",
    }}
  >
    <Container className="footerStyling pt-4  pt-md-5">
      <Row>
        <Col xs={12} md={3} className="">
          <div className="text-center">
            <a
              className="pr-2 mt-1"
              href="https://www.linkedin.com/company/opendemia/about/"
              target="_blank"
              rel="noreferrer"
            >
              <LazyLoadImage width="25" alt="LinkedIn" src={linkedInLogo} />
            </a>
            <a
              className="pr-2 pl-2 mt-1"
              href="https://www.facebook.com/opendemia"
              target="_blank"
              rel="noreferrer"
            >
              <LazyLoadImage width="25" alt="facebook" src={fblogo} />
            </a>

            <a
              className="pr-2 pl-2 mt-1"
              href="https://www.instagram.com/opendemia"
              target="_blank"
              rel="noreferrer"
            >
              <LazyLoadImage width="25" alt="instagram" src={igLogo} />
            </a>
          </div>
          <p className="text-center">
            <small className="text-small text-white">
              Opendemia LLC © {new Date().getFullYear()}
            </small>{" "}
          </p>
        </Col>
        <Col>
          <h5 className="text-white">Home</h5>
          <ul className="list-unstyled text-small">
            <li>
              {" "}
              <Link className="text-white-50" exact={+true} to="/">
                Student
              </Link>
            </li>
            <li>
              {" "}
              <Link className="text-white-50" exact={+true} to="/Educator">
                Educator
              </Link>
            </li>
          </ul>
        </Col>
        <Col>
          <h5 className="text-white">Learn More</h5>
          <ul className="list-unstyled text-small">
            {/* <li> <Link className="text-white-50" exact={+true} to="/Pricing">Pricing</Link></li> */}
            <li>
              {" "}
              <Link className="text-white-50" exact={+true} to="/About">
                About
              </Link>
            </li>
            <li>
              {" "}
              <Link className="text-white-50" exact={+true} to="/Pricing">
                Pricing
              </Link>
            </li>
            <li>
              {" "}
              <Link className="text-white-50" exact={+true} to="/News">
                News
              </Link>
            </li>
            <li>
              {" "}
              <Link className="text-white-50" exact={+true} to="/Blog">
                Blog
              </Link>
            </li>
            <li>
              {" "}
              <Link className="text-white-50" exact={+true} to="/Team">
                Team
              </Link>
            </li>
          </ul>
        </Col>

        <Col>
          <h5 className="text-white">Help</h5>
          <ul className="list-unstyled text-small">
            <li>
              {" "}
              <Link className="text-white-50" exact={+true} to="/classroom">
                Sign Up
              </Link>
            </li>
            <li>
              {" "}
              <Link className="text-white-50" exact={+true} to="/classroom">
                Log In
              </Link>
            </li>
            <li>
              {" "}
              <Link className="text-white-50" exact={+true} to="/FAQ">
                Tutorial
              </Link>
            </li>
            <li>
              {" "}
              <Link className="text-white-50" exact={+true} to="/Questions">
                FAQ
              </Link>
            </li>
            <li>
              {" "}
              <Link className="text-white-50" exact={+true} to="/Contact">
                Contact
              </Link>
            </li>

            <li>
              {" "}
              <Link className="text-white-50" exact={+true} to="/Legal">
                Legal
              </Link>
            </li>
          </ul>
        </Col>

        <Col>
          <h5 className="text-white">Thanks</h5>
          <p className="text-white-50">
            If you have any feedback please let us know via our&nbsp;
            <Link exact to="/Contact" className="text-white-50">
              contact page.
            </Link>
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Footer;
