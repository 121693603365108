import React from 'react';
import {
  Row,
  Col,
  Container,
} from 'react-bootstrap';

import FolderSVG from '../../Assets/LandingPage/free.png';
import DocsSVG from '../../Assets/LandingPage/Citations.png';
import ForgetSVG from '../../Assets/LandingPage/organisation.png';
import ComputerSVG from '../../Assets/LandingPage/group.png';
import LookingSVG from '../../Assets/LandingPage/outline.png';


const FeatureListAltContent = () => (
  <div className="firstInfoBlock">
    <Container>
      {/* <Row>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <p className="h1">Say Goodbye To...</p>
          <hr width="25%" />
        </div>
      </Row> */}

      <Row className="rowSpacingLanding">
        <Col sm={12} md={6} className="align-self-center">
          <div>
            <p className="h2"> Legit Free Version</p>
            {/* <FontAwesomeIcon icon={faFolderOpen} /> */}

            <p className="textSpacingLanding lead">
              {/* Create a folder for your assignment, add sources within,
                and take notes on them. We generate the full Works Cited
                and in-text citations for each quote. */}
              We limit storage, not functionality. So, you can actually finish multiple assignments without a paid account!            </p>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <img src={FolderSVG} style={{ width: '80%', marginBottom: '2ex' }} alt="Organize your bibliography" />
        </Col>

      </Row>

      <Row className="rowSpacingLanding altColorLanding">
        <Col sm={12} md={6}>
          <img src={DocsSVG} style={{ width: '80%', marginBottom: '2ex' }} alt="Organize your bibliography" />
        </Col>
        <Col sm={12} md={6} className="align-self-center">
          <div>
            <p className="h2">Citations of Course</p>
            {/* <FontAwesomeIcon icon={faPaperclip} /> */}
            <p className="textSpacingLanding lead">
              {/* Notes section for each source that keeps track of the
                quotes you want to use, where it was located in the
                source, and why you think it will be useful all in an
                organized and easily referenceable format. */}
              Use Opendemia to organize your sources and notes and we will generate a full Works Cited and in-text citations for you!
            </p>
          </div>
        </Col>
      </Row>

      <Row className="rowSpacingLanding">
        <Col sm={12} md={6} className="align-self-center">
          <div>
            <p className="h2">Organization Station</p>
            {/* <FontAwesomeIcon icon={faCloud} /> */}
            <p className="textSpacingLanding lead">
              {/* This is a library for every source you use throughout
                college, so you will be able to use your organized notes
                and citations for future assignments. */}
              Keep all sources and notes perfectly organized. Assisted notetaking. Always pick up where you left off on any computer. Save everything for future assignments!
            </p>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <img src={ForgetSVG} style={{ width: '80%', marginBottom: '2ex' }} alt="Organize your bibliography" />
        </Col>

      </Row>

      <Row className="rowSpacingLanding altColorLanding">
        <Col sm={12} md={6}>
          <img src={ComputerSVG} style={{ width: '80%', marginBottom: '2ex' }} alt="Organize your bibliography" />
        </Col>
        <Col sm={12} md={6} className="align-self-center">
          <div>
            <p className="h2">Outline Generator</p>
            {/* <FontAwesomeIcon icon={faQuestion} /> */}
            <p className="textSpacingLanding lead">
              {/* Like to work in the computer lab sometimes? You can attach
                PDFs of each source and access it, along with all of your
                other notes and citations, to use on any computer with
                internet access. */}
              Drag and drop each note to the order you want it within your paper and add additional thoughts throughout to make the rough draft a breeze!
            </p>

          </div>
        </Col>
      </Row>
      {/* <Row className="rowSpacingLanding">
        <Col sm={12} md={6} className="align-self-center">
          <div>
            <p className="h2">Outline Generator</p>
            
            <p className="textSpacingLanding lead">
             
              Drag and drop each note to the order you want it within your paper and add additional thoughts throughout to make the rough draft a breeze!            </p>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <img src={LookingSVG} style={{ width: '80%', marginBottom: '2ex' }} alt="Organize your bibliography" />
        </Col>

      </Row> 
    */}

    </Container>

  </div>
  // <div className="firstInfoBlock">
  //   <h3>Say Goodbye To:</h3>
  //   <hr width="25%" />
  //   <Carousel
  //     activeIndex={index}
  //     direction={direction}
  //     onSelect={handleSelect}
  //     interval={10000}
  //   >
  //     <Carousel.Item>
  //       <div className="container caroselQuote p-2 caroselText">
  //         <Row>
  //           <Col sm={12} md={6} className="caroselCol">
  //             <h4>Citation Headaches</h4>
  //             <FontAwesomeIcon icon={faFolderOpen} />
  //             <p>
  //               Create a folder for your assignment, add sources within,
  //               and take notes on them. We generate the full Works Cited
  //               and in-text citations for each quote.
  //                 </p>
  //           </Col>
  //           <Col sm={12} md={6} className="caroselCol">
  //             <h4>Messy Notes in a Word Doc</h4>
  //             <FontAwesomeIcon icon={faPaperclip} />
  //             <p>
  //               Notes section for each source that keeps track of the
  //               quotes you want to use, where it was located in the
  //               source, and why you think it will be useful all in an
  //               organized and easily referenceable format.
  //                 </p>
  //           </Col>
  //         </Row>
  //       </div>
  //     </Carousel.Item>
  //     <Carousel.Item>
  //       <div className="container caroselQuote p-2 caroselText">
  //         <Row>
  //           <Col sm={12} md={6} className="caroselCol">
  //             <h4> Finish and Forget Syndrome </h4>
  //             <FontAwesomeIcon icon={faCloud} />
  //             <p>
  //               This is a library for every source you use throughout
  //               college, so you will be able to use your organized notes
  //               and citations for future assignments.
  //                 </p>
  //           </Col>
  //           <Col sm={12} md={6} className="caroselCol">
  //             <h4>Not Having the File You Need</h4>
  //             <FontAwesomeIcon icon={faQuestion} />
  //             <p>
  //               Like to work in the computer lab sometimes? You can attach
  //               PDFs of each source and access it, along with all of your
  //               other notes and citations, to use on any computer with
  //               internet access.
  //                 </p>
  //           </Col>
  //         </Row>
  //       </div>
  //     </Carousel.Item>
  //     <Carousel.Item>
  //       <div className="container caroselQuote p-2 caroselText">
  //         <Row>
  //           <Col sm={12} md={6} className="caroselCol">
  //             <h4> Constantly Re-reading papers </h4>
  //             <FontAwesomeIcon icon={faBook} />
  //             <p>
  //               When picking a topic, you can take notes as you read
  //               sources so you don’t have to do it all again once you
  //               decide. Plus, it is saved for future papers.
  //                 </p>
  //           </Col>
  //           <Col sm={12} md={6} className="caroselCol">
  //             <h4>End of the Semester Blues</h4>
  //             <FontAwesomeIcon icon={faClock} />
  //             <p>
  //               You no longer need to have a full day set aside to work.
  //               Opendemia will allow you to stay organized throughout the
  //               semester and take quality notes on an article whenever you
  //               have spare time.
  //                 </p>
  //           </Col>
  //         </Row>
  //       </div>
  //     </Carousel.Item>
  //   </Carousel>
  // </div>
);

export default FeatureListAltContent;
