import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './CSS/index.css';
import { createStore } from 'redux';
import App from './App';
import rootReducer from './redux/reducers';
import { unregister } from './serviceWorker';

unregister();

const store = createStore(rootReducer);

const rootEl = document.getElementById('root');

ReactDOM.render(<App store={store} />, rootEl);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    ReactDOM.render(
      <NextApp store={store} />,
      rootEl,
    );
  });
}
