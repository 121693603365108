import React from "react";
import { Nav, Navbar, Dropdown } from "react-bootstrap";
import { Switch, withRouter, NavLink, Route } from "react-router-dom"; // this is the same as react router
import { Auth, I18n, Hub } from "aws-amplify";
import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Axios from "axios";
import SEO from "./Views/SEO";
import "./CSS/Dashboard.css";
import "./CSS/root.css";
import LandingPageView from "./Views/LandingPage/LandingPageView.js";
import logo from "./Assets/fullsizeoutput_806.png";
import { ToastAlert } from "./Views/SharedViews";
import { connect } from "react-redux";
import { mapStatetoProps } from "./redux/containers/login";
import { setLoginInfo } from "./redux/actions";
import { authTheme, baseURL, formFields } from "./Constants";
import styled from "styled-components";
import TeacherView from "./Views/Teacher/TeacherView";

const ClassroomView = React.lazy(() =>
  import("./Components/Library/ClassroomView")
);
const MainProfileView = React.lazy(() => import("./Views/MainProfileView.js"));
const FeedbackView = React.lazy(() => import("./Views/FeedbackView.js"));
const TeamView = React.lazy(() => import("./Views/Team Page/TeamView"));
const PriceView = React.lazy(() => import("./Views/Pricing/ViewPricePage"));
const BlogView = React.lazy(() => import("./Views/Blog/BlogView"));
const ContactView = React.lazy(() => import("./Views/ContactView.js"));
const LegalView = React.lazy(() => import("./Views/Legal/LegalView.js"));
const NewsView = React.lazy(() => import("./Views/In The News/NewsView"));
const AboutUsView = React.lazy(() => import("./Views/About Us/AboutUsView"));
const FAQView = React.lazy(() => import("./Views/FAQView.js"));
const ScholarshipView = React.lazy(() => import("./Views/ScholarshipView"));
const CareersView = React.lazy(() => import("./Views/Careers/Careers"));
const QuestionsView = React.lazy(() => import("./Views/FAQPage/QuestionsView"));

const DynamicBlogImport = (i) =>
  React.lazy(() => import("./Views/Blog/Posts/BlogPost" + i));

const AddSuspense = (Children, scrollFun) => {
  window.removeEventListener("scroll", scrollFun);
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Children />
    </React.Suspense>
  );
};

const authScreenLabels = {
  en: {
    Username: "Email",
    username: "email",
  },
};
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    style={{ borderStyle: "none", outline: "none" }}
    className="nav-link nav-dropdown-opendemia"
    id="navButton"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </button>
));

I18n.setLanguage("en");
I18n.putVocabularies(authScreenLabels);

const seoConfig = {
  title: "Opendemia",
  description:
    "Helps you write a research paper fast by keeping your research organized and generating the in-text citations and full works cited.",
  keywords: [
    "How to write a research paper",
    "How to write a research paper fast",
    "How to start a research paper",
    "Help writing a research paper",
    "Research paper tips",
  ],
};

const AuthDiv = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20%;
`;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signedIn: false,
      menuBarClicked: false,
      scrolly: 0,
      initialAuthState: "signUp",
      showToast: false,
      toast: {
        title: undefined,
        message: undefined,
      },
    };
    this.setAuth(false);
  }

  toggleMenubar = () => {
    this.setState({ menuBarClicked: !this.state.menuBarClicked });
  };

  updateCurrentUser = () => {
    // updates the current logged in user to state
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        Axios.defaults.headers.common.Authorization = `Bearer ${user.signInUserSession.idToken.jwtToken}`;
        this.setAuth(true);
        this.setState({ signedIn: true });
        if (window.gtag) {
          window.gtag("event", "login", {
            user_id: user.username,
            user_agent: window.navigator.userAgent,
          });
        }

        //pulls info to see if user has paid
        Axios.get(`${baseURL}/profiles/${user.username}`).then((res) => {
          // console.log("DATA1")
          // console.log(res.data)
          this.props.dispatch(setLoginInfo(res.data));
        });
      })
      .catch((err) => {
        console.log(err);
        this.setAuth(false);
        this.setState({ signedIn: false });
      });
  };

  componentDidUpdate() {
    this.setAuth(this.state.signedIn);
  }

  componentDidMount() {
    this.updateCurrentUser();

    this.props.history.listen((location) => {
      if (window.gtag) {
        window.gtag("event", "page_view", {
          page_path: location.pathname + location.search,
        });
      }
    });

    Hub.listen("auth", (data) => {
      if (data.payload.event === "signIn") {
        this.setAuth(true);
        this.setState({ signedIn: true, showToast: false });
      } else if (
        data.payload.event === "signOut" ||
        data.payload.event === "signIn_failure"
      ) {
        this.setAuth(false);
        this.setState({ signedIn: false });
      } else if (data.payload.event === "signUp_failure") {
        this.setState({
          showToast: true,
          toast: {
            title: data.payload.message,
            message: data.payload.data.message,
          },
        });
      } else if (data.payload.event === "signUp") {
        this.setState({ showToast: false });
      }
    });

    //scroll height updater
    this.updateDimensions();
    window.addEventListener("scroll", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ scrolly: window.scrollY });
  };

  setAuth(state) {
    this.classroomWithAuth = this.confirmAuth(ClassroomView, state);
    this.profileWithAuth = this.confirmAuth(MainProfileView, state);
    this.feedbackWithAuth = this.confirmAuth(FeedbackView, state);
  }

  renderMenubarItems = () => (
    <Nav className="ml-auto">
      {
        /*Scrolling on homepage */
        this.state.scrolly > 450 &&
          this.props.location.pathname == "/" &&
          !this.state.signedIn && (
            <>
              <NavLink
                exact
                to="/classroom"
                onClick={(k) => {
                  this.setState({ initialAuthState: "signup" });
                }}
                className="nav-link btn btn-success noHoverImportant"
                id="navButton"
                style={{ position: "absolute", left: "45.5%" }}
              >
                Sign Up
              </NavLink>

              <NavLink
                exact
                to="/classroom"
                onClick={(k) => {
                  this.setState({ initialAuthState: "signIn" });
                }}
                className="nav-link btn btn-outline-secondary"
                id="navButton"
                style={{
                  marginLeft: "10px",
                  marginRight: "3em",
                  position: "absolute",
                  left: "51.5%",
                }}
              >
                Sign In
              </NavLink>
            </>
          )
      }

      {
        /* Always show upgrade button if you are signed in and not paid */
        !this.props.paid && this.state.signedIn && (
          <NavLink
            exact
            to="/Pricing"
            className="nav-link btn btn-success noHoverImportant"
            id="navButton"
          >
            Upgrade
          </NavLink>
        )
      }

      {/* {
        this.props.location.pathname == "/classroom" && !this.props.paid && this.state.signedIn &&
        <NavLink exact to="/Pricing" className="nav-link btn btn-success" id="navButton">
          Upgrade
        </NavLink>
      } */}

      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          Help
          {/* &#x25bc; */}
        </Dropdown.Toggle>
        <Dropdown.Menu className="nav-dropdown-opendemia-inside">
          <Dropdown.Item>
            <NavLink exact to="/FAQ">
              Tutorial
            </NavLink>
          </Dropdown.Item>
          <Dropdown.Item>
            <NavLink exact to="/Questions">
              FAQ
            </NavLink>
          </Dropdown.Item>
          <Dropdown.Item>
            <NavLink exact to="/Contact">
              Contact
            </NavLink>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {this.state.signedIn && (
        <>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle}>
              About
              {/* &#x25bc; */}
            </Dropdown.Toggle>
            <Dropdown.Menu className="nav-dropdown-opendemia-inside">
              <Dropdown.Item>
                <NavLink exact to="/About">
                  About Us
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item>
                <NavLink exact to="/News">
                  In the News
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item>
                <NavLink exact to="/Team">
                  Team
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item>
                <NavLink exact to="/Blog">
                  Blog
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item>
                <NavLink exact to="/Careers">
                  Careers
                </NavLink>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <NavLink exact to="/classroom" className="nav-link" id="navButton">
            Library
          </NavLink>
          <NavLink exact to="/Profile" className="nav-link" id="navButton">
            Profile
          </NavLink>
        </>
      )}

      {(!this.state.signedIn || this.props.location.pathname == "/") &&
        !(this.state.signedIn && this.props.location.pathname == "/") && (
          <>
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle}>
                About
                {/* &#x25bc; */}
              </Dropdown.Toggle>
              <Dropdown.Menu className="nav-dropdown-opendemia-inside">
                <Dropdown.Item>
                  <NavLink exact to="/About">
                    About Us
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/News">
                    In the News
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/Team">
                    Team
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/Blog">
                    Blog
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/Careers">
                    Careers
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/*  <NavLink exact to="/Blog" > className="nav-link" id="navButton"> */}

            {/* Navbutton should be made a class for this button if it is causing issues */}
            <NavLink exact to="/Pricing" className="nav-link" id="navButton">
              Pricing
            </NavLink>
          </>
        )}

      {!this.state.signedIn && true && (
        <>
          {/* <NavLink exact to="/classroom"  onClick={k =>{alert("clickedsup")}}style={{ color: 'white' }}>
            Sign Up
          </NavLink> */}
          <NavLink
            exact
            to="/classroom"
            className="nav-link"
            onClick={(k) => {
              this.setState({ initialAuthState: "signIn" });
            }}
            id="navButton"
          >
            Sign In
          </NavLink>
        </>
      )}
    </Nav>
  );

  confirmAuth = (Children, state) =>
    state ? (
      AddSuspense(Children, this.updateDimensions)
    ) : (
      <AuthDiv>
        <ThemeProvider theme={authTheme}>
          <Authenticator
            initialState={this.state.initialAuthState}
            formFields={formFields}
          />
        </ThemeProvider>
      </AuthDiv>
    );

  setStateToSignin = () => {
    this.setState({ initialAuthState: "signIn" });
  };

  render() {
    return (
      <div id="outerDiv">
        <SEO {...seoConfig} />
        {/* <AlertBar
          show={this.props.location.pathname === "/"}
          text={alertText}
        /> */}
        <Navbar
          id="dashboard"
          className="testHeader"
          collapseOnSelect
          expand="lg"
          sticky="top"
        >
          <Navbar.Brand href="/" style={{ display: "flex" }}>
            <img src={logo} className="logoClass" alt="logo" />
            {this.props.paid && <span className="shiny">PRO</span>}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{ filter: "" }}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            {this.renderMenubarItems()}
          </Navbar.Collapse>
        </Navbar>
        <div className="content">
          {this.state.showToast && (
            <ToastAlert
              title={this.state.toast.title}
              message={this.state.toast.message}
            />
          )}
          <Switch>
            <Route
              exact="true"
              path="/"
              render={(props) => (
                <LandingPageView
                  {...props}
                  signedIn={this.state.signedIn}
                  setUpperStateToLogin={this.setStateToSignin}
                />
              )}
            />
            <Route
              exact="true"
              path="/index.html"
              component={LandingPageView}
            />

            <Route
              path="/classroom"
              render={(props) => this.classroomWithAuth}
            />

            <Route path="/Profile" render={(props) => this.profileWithAuth} />
            {/* <Route
              exact
              path="/Feedback"
              component={this.feedbackWithAuth}
            /> */}
            <Route
              exact
              path="/Contact"
              render={() => AddSuspense(ContactView, this.updateDimensions)}
            />
            <Route
              exact
              path="/FAQ"
              render={() => AddSuspense(FAQView, this.updateDimensions)}
            />
            <Route
              exact
              path="/Legal"
              render={() => AddSuspense(LegalView, this.updateDimensions)}
            />
            <Route
              exact
              path="/About"
              render={() => AddSuspense(AboutUsView, this.updateDimensions)}
            />
            <Route
              exact
              path="/News"
              render={() => AddSuspense(NewsView, this.updateDimensions)}
            />
            <Route
              exact
              path="/Team"
              render={() => AddSuspense(TeamView, this.updateDimensions)}
            />
            <Route
              exact
              path="/Questions"
              render={() => AddSuspense(QuestionsView, this.updateDimensions)}
            />
            <Route
              exact
              path="/Blog"
              render={() => AddSuspense(BlogView, this.updateDimensions)}
            />
            <Route
              exact
              path="/Pricing"
              render={() => AddSuspense(PriceView, this.updateDimensions)}
            />
            <Route exact path="/Scholarship" component={ScholarshipView} />
            <Route
              exact
              path="/Careers"
              component={() => AddSuspense(CareersView, this.updateDimensions)}
            />

            <Route exact path="/Educator" render={(props) => <TeacherView />} />

            <Route
              exact
              path="/Blog/6-Steps-to-Finishing-Your-Paper-More-Quickly-and-Easily"
              render={() =>
                AddSuspense(DynamicBlogImport(1), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/4-FREE-Must-Haves-to-Succeed-in-College"
              render={() =>
                AddSuspense(DynamicBlogImport(2), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/Is-Doing-Research-in-College-Worth-it"
              render={() =>
                AddSuspense(DynamicBlogImport(3), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/How-to-Land-an-Interview-for-Your-Dream-Internship"
              render={() =>
                AddSuspense(DynamicBlogImport(4), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/How-to-Stop-Procrastinating-and-Pick-a-Topic-for-Your-Paper"
              render={() =>
                AddSuspense(DynamicBlogImport(5), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/Top-Three-Tips-for-Time-Management"
              render={() =>
                AddSuspense(DynamicBlogImport(6), this.updateDimensions)
              }
            />

            <Route
              exact
              path="/Blog/How-to-Make-Yourself-Go-to-Bed-Earlier"
              render={() =>
                AddSuspense(DynamicBlogImport(7), this.updateDimensions)
              }
            />

            <Route
              exact
              path="/Blog/Resetting-Your-Sleep-Routine"
              render={() =>
                AddSuspense(DynamicBlogImport(8), this.updateDimensions)
              }
            />

            <Route
              exact
              path="/Blog/5-Ways-to-Spice-Up-Your-Zoom-Morning-Routine"
              render={() =>
                AddSuspense(DynamicBlogImport(9), this.updateDimensions)
              }
            />

            <Route
              exact
              path="/Blog/Surviving-the-Semester-Sans-Spring-Break"
              render={() =>
                AddSuspense(DynamicBlogImport(10), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/Rediscovering-Reading-as-a-Student"
              render={() =>
                AddSuspense(DynamicBlogImport(11), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/Tips-to-Finish-the-Semester-Strong"
              render={() =>
                AddSuspense(DynamicBlogImport(12), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/What-to-Look-For-in-a-Summer-Job"
              render={() =>
                AddSuspense(DynamicBlogImport(13), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/7-Simple-Ways-to-Improve-Your-Writing"
              render={() =>
                AddSuspense(DynamicBlogImport(14), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/How-to-Set-Up-an-Effective-LinkedIn-Profile"
              render={() =>
                AddSuspense(DynamicBlogImport(15), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/College-Visit-Tips"
              render={() =>
                AddSuspense(DynamicBlogImport(16), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/Avoiding-the-Mid-Summer-Slump"
              render={() =>
                AddSuspense(DynamicBlogImport(17), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/What-Students-Need-to-Know-About-Credit-Cards"
              render={() =>
                AddSuspense(DynamicBlogImport(18), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/Fun-Ways-to-Relax-This-Fall"
              render={() =>
                AddSuspense(DynamicBlogImport(19), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/How-to-Space-Out-a-Research-Project"
              render={() =>
                AddSuspense(DynamicBlogImport(20), this.updateDimensions)
              }
            />

            <Route
              exact
              path="/Blog/Refreshing-Your-Routine-Mid-Semester"
              render={() =>
                AddSuspense(DynamicBlogImport(21), this.updateDimensions)
              }
            />

            <Route
              exact
              path="/Blog/Starting-Back-to-School-on-the-Right-Foot"
              render={() =>
                AddSuspense(DynamicBlogImport(22), this.updateDimensions)
              }
            />

            <Route
              exact
              path="/Blog/College-Application-Tips"
              render={() =>
                AddSuspense(DynamicBlogImport(23), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/Adjusting-to-Life-at-Home-Over-Winter-Break"
              render={() =>
                AddSuspense(DynamicBlogImport(24), this.updateDimensions)
              }
            />

            <Route
              exact
              path="/Blog/How-to-Make-the-Most-of-Small-Blocks-of-Time"
              render={() =>
                AddSuspense(DynamicBlogImport(25), this.updateDimensions)
              }
            />

            <Route
              exact
              path="/Blog/Surviving-While-Sleep-Deprived"
              render={() =>
                AddSuspense(DynamicBlogImport(26), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/5-Easy-Hacks-to-Fight-Procrastination"
              render={() =>
                AddSuspense(DynamicBlogImport(27), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/4-Tips-to-Find-the-Internship-of-Your-Dreams"
              render={() =>
                AddSuspense(DynamicBlogImport(28), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/How-to-Incorporate-Downtime-into-your-Life-as-a-Student"
              render={() =>
                AddSuspense(DynamicBlogImport(29), this.updateDimensions)
              }
            />
            <Route
              exact
              path="/Blog/How-to-Reset-a-Bad-Day"
              render={() =>
                AddSuspense(DynamicBlogImport(30), this.updateDimensions)
              }
            />

            <Route
              exact
              path="/Blog/How-to-Crank-Out-that-Research-Paper-Youve-Been-Procrastinating-On"
              render={() =>
                AddSuspense(DynamicBlogImport(31), this.updateDimensions)
              }
            />

            <Route
              path="*"
              render={() => <LandingPageView signedIn={this.state.signedIn} />}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStatetoProps)(Dashboard));
