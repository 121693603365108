export const LOGIN = 'LOGIN';
export const CLASSES = 'CLASSES';
export const SET_CLASSES = 'SET_CLASSES';

export const setLoginInfo = (user) => ({
  type: LOGIN,
  payload: {
    bio: user.bio,
    firstName: user.firstName,
    imageLocation: user.imageLocation,
    jobTitle: user.jobTitle,
    lastName: user.lastName,
    university: user.university,
    userName: user.userName,
    adminUserName: user.adminUserName,
    personalEmail: user.personalEmail,
    major: user.major,
    classes: user.classes,
    loginEmail: user.loginEmail,
    schoolEmail: user.schoolEmail,
    paid: user.paid,
    cancelled: user.cancelled,
  },
});

export const updateClasses = (classes) => ({
  type: CLASSES,
  payload: { classes },
});

export const setClasses = (classes) => ({
  type: SET_CLASSES,
  payload: { classes },
});
