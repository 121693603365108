import React, { useState, useEffect, useRef } from "react";
import { Button, OverlayTrigger, Tooltip, Toast, Badge } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "../CSS/MutableInput.sass";

export const AlertBar = (props) => {
  if (!props.show) {
    return <div />;
  }
  return (
    <div>
      <h4
        id="alertBar"
        className="d-block px-3 py-2 text-center text-bold text-white"
        style={{ marginBottom: 0 }}
      >
        {props.text}
      </h4>
    </div>
  );
};

export const ToolTipOverlay = ({ text, direction, children }) => (
  <OverlayTrigger
    placement={direction || "auto"}
    overlay={<Tooltip>{text}</Tooltip>}
  >
    {children}
  </OverlayTrigger>
);

export const ToastAlert = ({ title, message }) => {
  const [show, setShow] = useState(true);
  const toggleShow = () => setShow(!show);

  return (
    <Toast show={show} onClose={toggleShow}>
      <Toast.Header>
        <strong className="mr-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
};

const LoadingSpinner = () => (
  <div>
    <FontAwesomeIcon icon={faSpinner} spin />
    Loading...
  </div>
);

export const LoadingButton = () => (
  <Button variant="outline-dark" disabled>
    <span
      className="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    />
    <span className="sr-only">Loading...</span>
  </Button>
);

export default LoadingSpinner;

export const MutableInput = ({
  value,
  valueSet,
  placeHolder,
  isTitle,
  className,
}) => {
  const currentField = useRef(null);
  const [edit, editSet] = useState(false);
  const [input, setInput] = useState(value);

  const styleAddOn = !isTitle ? " mi-description" : "";

  //* Initiates textarea onClick, selects all text & creates sets height
  useEffect(() => {
    if (edit) {
      currentField.current.select();
      currentField.current.style.height =
        currentField.current.scrollHeight + "px";
    } else if (value !== input && input !== "") {
      valueSet(value, input);
    } else if (value !== input && input === "") {
      alert("Section name cannot be blank");
      setInput(value);
    }
  }, [edit]);

  useEffect(() => setInput(value), [value]);

  //* Changes size of text area as more text is added
  function auto_grow(e) {
    e.target.style.height = e.target.scrollHeight + "px";
  }

  //* Enter key & outsideclick handler
  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e === "outside") {
      editSet(false);
    }
  };

  //* Handles value change & trims spaces in the beginning
  const handleInputChange = (e) => {
    setInput(e.target.value.trimStart());
  };

  return (
    <div className={`w-100 ${className}`}>
      <OutsideClickHandler onOutsideClick={() => handleKeyPress("outside")}>
        <div id="mi">
          {edit ? (
            <textarea
              className={"mi-textarea" + styleAddOn}
              onInput={auto_grow}
              type="text"
              value={input}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              ref={currentField}
              placeholder={placeHolder}
              rows={isTitle ? "1" : "2"}
            />
          ) : (
            <div
              onClick={() => editSet(true)}
              className={"mi-div" + styleAddOn}
            >
              {input !== "" ? input : placeHolder}
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export const plusSign = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="plus"
    className="svg-inline--fa fa-plus fa-w-14 "
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
    />
  </svg>
);
