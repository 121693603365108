import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { Link } from "react-router-dom"; // this is the same as react router

const QuestionsViewTeacher = (props) => (
  <div>
    <div className="PricingHeader text-center mr-5 ml-5 mb-5">
      <p className="display-3 mb-2">FAQ</p>
      <p className="lead">Click to see answers to common questions.</p>
    </div>
    <div className="content-container">
      <Accordion className="mb-3">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            How does Opendemia differ from EasyBib or Citation Machine?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              Opendemia is much more than just a citation generator. Our FREE
              website helps students keep all their sources and notes organized,
              provides prompts on how to take better notes, allows them to
              easily drag and drop these notes into a rough draft, and
              autogenerates all in-text citations and the full works cited.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            How much does Opendemia cost?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              We have both Free and Pro versions of the site, and the only
              functional difference is the storage limit. Our free version
              currently allows 15 sources at a time which can be deleted. So, if
              a student writes a paper that has 10 sources, they can delete that
              before starting another 10-source paper all on the free version.
              The pro version has unlimited storage which allows students to
              begin building a portfolio of work.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            Is Opendemia trustworthy?{" "}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              Yes! (1) Opendemia has a stamp of approval from multiple
              universities. (2) User info is not sold and is stored in a secure
              and encrypted database. (3) For the Pro Version, all payments are
              made through PayPal.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  </div>
);
export default QuestionsViewTeacher;
