import React from 'react';
import { HashRouter, BrowserRouter } from 'react-router-dom'; // this is the same as react router
import { Provider } from 'react-redux';

import Amplify from 'aws-amplify';
import Dashboard from './Dashboard.js';

import config from './aws-exports';
import './CSS/custom.css';
import ScrollToTop from './ScrollToTop.js';

Amplify.configure(config);
// Amplify.configure(awsmobile)

Amplify.configure({
  Auth: {

    // REQUIRED - Amazon Cognito Region
    region: 'US-EAST-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-1_ojqdOkgL7',

    userPoolWebClientId: 'tk9q0q95h7l9ovt8v320qnuhm',
  },
});

const App = ({ store }) => (
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <Dashboard />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>
);

// export default withAuthenticator(App, true);
export default App;
