import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import FolderSVG from "../../Assets/LandingPage/free.png";
import DocsSVG from "../../Assets/LandingPage/Citations.png";
import ForgetSVG from "../../Assets/LandingPage/organisation.png";
import ComputerSVG from "../../Assets/LandingPage/group.png";
import LookingSVG from "../../Assets/LandingPage/outline.png";

const FeatureListAltContentTeacher = () => (
  <div className="firstInfoBlock">
    <Container>
      <Row className="rowSpacingLanding">
        <Col sm={12} md={6} className="align-self-center">
          <div>
            <p className="h2"> What Opendemia Does</p>
            {/* <FontAwesomeIcon icon={faFolderOpen} /> */}
            <p className="textSpacingLanding lead">
              {/* Create a folder for your assignment, add sources within,
                and take notes on them. We generate the full Works Cited
                and in-text citations for each quote. */}
              We surveyed over 1,500 students and a resounding 86% shared that
              they hate, or get anxiety about writing papers, so Opendemia.com
              was designed by students & teachers to help with this problem.
            </p>{" "}
          </div>
        </Col>
        <Col sm={12} md={6}>
          <img
            src={ForgetSVG}
            style={{ width: "80%", marginBottom: "2ex" }}
            alt="Organize your bibliography"
          />
        </Col>
      </Row>

      {/* <Row className="rowSpacingLanding altColorLanding">
        <Col sm={12} md={6}>
          <img
            src={DocsSVG}
            style={{ width: "80%", marginBottom: "2ex" }}
            alt="Organize your bibliography"
          />
        </Col>
        <Col sm={12} md={6} className="align-self-center">
          <div>
            <p className="h2">
              How does Opendemia differ from EasyBib or Citation Machine?
            </p>
            
            <p className="textSpacingLanding lead">
             
              Opendemia is much more than just a citation generator. Our FREE
              website helps students keep all their sources and notes organized,
              provides prompts on how to take better notes, allows them to
              easily drag and drop these notes into a rough draft, and
              autogenerates all in-text citations and the full works cited.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="rowSpacingLanding">
        <Col sm={12} md={6} className="align-self-center">
          <div>
            <p className="h2">How much does Opendemia cost?</p>
            <p className="textSpacingLanding lead">
              We have both Free and Pro versions of the site, and the only
              functional difference is the storage limit. Our free version
              currently allows 15 sources at a time which can be deleted. So, if
              a student writes a paper that has 10 sources, they can delete that
              before starting another 10-source paper all on the free plan. The
              pro version has unlimited storage which allows students to begin
              building a portfolio of work.
            </p>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <img
            src={ForgetSVG}
            style={{ width: "80%", marginBottom: "2ex" }}
            alt="Organize your bibliography"
          />
        </Col>
      </Row>

      <Row className="rowSpacingLanding altColorLanding">
        <Col sm={12} md={6}>
          <img
            src={ComputerSVG}
            style={{ width: "80%", marginBottom: "2ex" }}
            alt="Organize your bibliography"
          />
        </Col>
        <Col sm={12} md={6} className="align-self-center">
          <div>
            <p className="h2">-Is Opendemia trustworthy?</p>
            <p className="textSpacingLanding lead">
              Yes! (1) Opendemia has a stamp of approval from multiple
              universities. (2) User info is not sold and is stored in a secure
              and encrypted database. (3) For the Pro Version, all payments are
              made through PayPal.
            </p>
          </div>
        </Col>
      </Row> */}
    </Container>
  </div>
);

export default FeatureListAltContentTeacher;
