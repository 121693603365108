import React from "react";
import "../../CSS/LandingPageView.css";
import "../../../node_modules/video-react/dist/video-react.css";
import { ResponsiveEmbed, Button, Row, Col, Container } from "react-bootstrap";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import { Redirect, Link } from "react-router-dom";
// import fblogo from "../../Assets/fb-logo.png";
// import linkedInLogo from "../../Assets/linkedIn.png";
// import igLogo from "../../Assets/IG_Glyph_Fill.jpg"
// import NewsletterSignUp from "./NewsletterSignUp";
// import UserQuotes from './UserQuotes';
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import YoutubePanel from "./YoutubePanel";
// import FeatureList from "./FeatureList";
import FeatureListAltContent from "./FeatureAltContent";
// import demoScreenshot from "../../Assets/demoScreenshot"
import Footer from "./Footer";
import QuoteCarousel from "./QuoteCarousel";
import HeroSVG from "../../Assets/landingpage_hero.svg";
import GraceCover from "../../Assets/LandingPage/GApprove.jpg";
import GraceVid from "../../Assets/LandingPage/GraceVideoComp22.mp4";

// http://35.175.115.110:8080/swagger-ui.html

function LazyLoadYouTube() {
  return (
    <LazyLoadComponent>
      <div>
        <ResponsiveEmbed className="" aspectRatio="16by9">
          <iframe
            className="embed-responsive-item"
            title="YouTube Video"
            src="https://www.youtube.com/embed/bZlombD_AQA?vq=hd720&rel=0&modestbranding=1&showinfo=0&controls=1"
            loading="lazy"
            name="YouTube Video"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </ResponsiveEmbed>
      </div>
    </LazyLoadComponent>
  );
}

class LandingPageView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {}

  setRedirect = () => {
    this.setState({
      redirect: true,
      redirText: "/classroom",
    });
  };

  redirectToScholarship = () => {
    console.log("hit");
    this.setState({
      redirect: true,
      redirText: "/Scholarship",
    });
  };

  setUpperStateToLogin = () => {
    console.log(this.props);
    this.props.setUpperStateToLogin();
  };

  submitNewsletter = (obj) => {
    Axios.post("http://34.201.1.139:8080/newsletter", obj);
  };

  renderRedirect = () => {
    if (this.state.redirect === true) {
      return <Redirect to={this.state.redirText} />;
    }

    return null;
  };

  iconBullet = (text) => (
    <p
      className="d-flex justify-content-left w-100 hero-bullet-text text-color-darkmode opendemiaBodyFont"
      style={{ marginBottom: "5px" }}
    >
      {" "}
      <FontAwesomeIcon
        size="2x"
        icon={faCheckCircle}
        className="checkboxMobileStyling"
      />{" "}
      <span
        className=""
        style={{ fontSize: "large", textAlign: "left", paddingLeft: "5px" }}
      >
        {text}
      </span>
    </p>
  );

  landingThree = () => (
    <React.Fragment>
      {this.renderRedirect()}

      <div className="styleDiv">
        {/* <div 
          className="marquee font-weight-bold font-italic"
        >
          <div>
            <span className="underlineOnHover" onClick={() => this.redirectToScholarship()}>$500 Scholarship Contest..... click to learn more!</span>
            <span className="underlineOnHover" onClick={() => this.redirectToScholarship()}>$500 Scholarship Contest..... click to learn more!</span>
            <span className="underlineOnHover" onClick={() => this.redirectToScholarship()}>$500 Scholarship Contest..... click to learn more!</span>
            <span className="underlineOnHover" onClick={() => this.redirectToScholarship()}>$500 Scholarship Contest..... click to learn more!</span>
            <span className="underlineOnHover" onClick={() => this.redirectToScholarship()}>$500 Scholarship Contest..... click to learn more!</span>
          </div>
        </div> */}
        <Container>
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#41a047" fill-opacity="1" d="M0,224L1440,128L1440,0L0,0Z"></path></svg> */}
          <Row style={{ paddingTop: "35px" }}>
            <Col sm={1}></Col>
            <Col className="align-items-left" xs={12} sm={7}>
              <div style={{ paddingTop: "2ex" }}>
                <h1 className="font-weight-bold titleFontSize text-left w-100 text-color-darkmode opendemiaLogoFont">
                  Research &#38; Writing Made Easy
                </h1>
              </div>
              <div style={{}}>
                <h3 className="text-left w-100 mb-3 text-color-darkmode font-weight-light opendemiaBodyFont">
                  Assistance throughout your entire paper
                </h3>
              </div>

              <Row xs={12}>
                <Col md={12}>
                  {this.iconBullet("Full Works Cited & In-Text Citations")}

                  {this.iconBullet("Assisted Note Taking & Organization")}

                  {/* {this.iconBullet('Group Project Help')} */}
                  {this.iconBullet("Outline Generator")}
                </Col>
              </Row>

              {!this.props.signedIn ? (
                <>
                  <Button
                    className="landerTopCreateFreeAccount mt-4 btn-block createaccountButton"
                    onClick={this.setRedirect}
                    style={{ borderRadius: "15px" }}
                  >
                    {this.props.signedIn ? (
                      <span>Go to your Library</span>
                    ) : (
                      <span>Create Free Account</span>
                    )}
                  </Button>

                  <p className="text-muted" style={{ marginLeft: "68px" }}>
                    No credit card required
                  </p>

                  {/* <Row>
                    <Button
                      className="mt-4 btn-outline-success buttonBox3 createaccountButton"
                      onClick={k => { this.setUpperStateToLogin(); this.setRedirect() }}
                      style={{ marginBottom: "15ex", borderRadius: "20" }}
                    >
                      <span>I Already Have An Account</span>

                    </Button>
                  </Row> */}
                </>
              ) : (
                <Row>
                  <Button
                    className="landerTopCreateFreeAccount mt-4 btn-block createaccountButton"
                    onClick={this.setRedirect}
                  >
                    <span>Go To Your Library</span>
                  </Button>
                </Row>
              )}
            </Col>
            <Col xs={12} sm={3}>
              {/* <img src={GraceCover} style={{ width: "300px", height: "533px", margin: "auto", display: "block" }} /> */}

              <LazyLoadComponent>
                <div>
                  <ResponsiveEmbed
                    className=""
                    aspectRatio="16by9"
                    style={{
                      width: "281px",
                      height: "500px",
                      margin: "auto",
                      display: "block",
                    }}
                  >
                    <iframe
                      className="embed-responsive-item"
                      title="YouTube Video"
                      src="https://www.youtube.com/embed/tmWFLJg5Vh8?vq=hd720&rel=0&modestbranding=1&showinfo=0&controls=1"
                      loading="lazy"
                      name="YouTube Video"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </ResponsiveEmbed>
                </div>
              </LazyLoadComponent>

              {/* <video style={{ width: "281px", height: "500px", margin: "auto", display: "block" }} poster={GraceCover} controls>
                <source src={process.env.PUBLIC_URL + "GraceV2.mp4"} type="video/mp4" />
                Your browser does not support this video.
              </video> */}
            </Col>
            <Col sm={1}></Col>
          </Row>
        </Container>
      </div>

      <Container className="mt-4">
        {/* <img
          src={HeroSVG}
          style={{
            width: '70%', marginBottom: '3ex', display: 'block', maxHeight: '200px', marginRight: 'auto', marginLeft: 'auto',
          }}
          alt="Organize your bibliography"
        /> */}
        <FeatureListAltContent />

        <QuoteCarousel />

        <YoutubePanel yt={LazyLoadYouTube} />
      </Container>

      <Container className="pb-4">
        <Col className="align-items-center">
          <Row
            xs={12}
            className="text-center d-md-flex justify-content-center align-items-center"
          >
            <h2 className="">Ready To Start?</h2>
          </Row>
          <Row lg={12} sm={12} className="pt-4">
            <Button className="buttonBox2 btn-block" onClick={this.setRedirect}>
              <span>Create Free Account</span>
            </Button>
          </Row>
        </Col>
      </Container>

      <div className="altColorLanding pt-4 pb-2">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs={12} className="mb-md-2 text-center">
              <h6>Used by High School - PhD Students Everywhere</h6>
            </Col>
            <Col
              xs={3}
              lg={3}
              className="text-center"
              style={{ padding: "0px" }}
            >
              <p className="lead text-muted" style={{ fontSize: "1rem" }}>
                {" "}
                Ohio State
              </p>
            </Col>
            <Col
              xs={3}
              lg={3}
              className="text-center"
              style={{ padding: "0px" }}
            >
              <p className="lead text-muted" style={{ fontSize: "1rem" }}>
                Vanderbilt
              </p>
            </Col>
            <Col
              xs={3}
              lg={3}
              className="text-center"
              style={{ padding: "0px" }}
            >
              <p className="lead text-muted" style={{ fontSize: "1rem" }}>
                Harvard
              </p>
            </Col>
            <Col
              xs={12}
              lg={3}
              className="text-center"
              style={{ padding: "0px" }}
            >
              <p className="lead text-muted" style={{ fontSize: "1rem" }}>
                And 100’s of Others
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </React.Fragment>
  );

  render() {
    return this.landingThree();
  }
}

export default LandingPageView;
