import React, { useRef } from "react";
import Carousel from "react-elastic-carousel";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";

const renderQuote = (name, place, text) => (
  <Card style={{ borderColor: "transparent" }}>
    <Card.Body className="p-5">
      <div className="d-flex justify-content-center mb-4">
        <FontAwesomeIcon icon={faQuoteRight} />
      </div>
      <figure className="text-center mb-0">
        <blockquote className="blockquote mb-4">
          <p>{text}</p>
        </blockquote>
        <figcaption className="blockquote-footer mb-0">
          <div>{name}</div>
          <div>{place}</div>
        </figcaption>
      </figure>
    </Card.Body>
  </Card>
);

function QuoteCarousel() {
  const carouselRef = useRef(null);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    {
      width: 550,
      itemsToShow: 2,
      itemsToScroll: 1,
      pagination: false,
    },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];

  const infiniteScroll = ({ index }) => {
    if (index >= 8) carouselRef.current.goTo(0);
  };

  return (
    <Carousel
      //style={{ backgroundColor: "whitesmoke" }}
      itemsToShow={1}
      pagination={false}
      // breakPoints={breakPoints}
      ref={carouselRef}
      onNextEnd={infiniteScroll}
    >
      {renderQuote(
        "Chloe",
        "University of Windsor",
        "Finally something easier! Profs always try to sell Zotero as the easy option, but truth be told you need an above average tech knowledge to use it well."
      )}
      {renderQuote(
        "KYLE",
        "HEIDELBERG UNIVERSITY",
        "Very simple to use and beyond helpful!"
      )}
      {renderQuote(
        "Dana",
        "Arizona State University",
        "Opendemia is awesome! Honestly, I went to the website thinking it was going to be this complicated and expensive thing. I love the tutorial; it shows me how easy it is to use and why it’s different than say EasyBib."
      )}
      {renderQuote(
        "Sydney",
        "High School Student",
        "Very well organized I love how convenient it was! "
      )}
      {renderQuote(
        "Rebekah",
        "High School Student",
        "I love Opendemia. I thought it was very helpful in keeping my sources organized."
      )}
      {renderQuote(
        "Dylan",
        "The Ohio State University",
        "This is great for streamlining paper writing."
      )}
      {renderQuote(
        "Mason",
        "University of Toledo",
        "This expedited my paper writing process and I will definitly be sharing with my friends."
      )}
      {renderQuote(
        "Liz",
        "Clemson University",
        "I love that all sources and citations are put together in one place and that you can mark specific quotes within sources as used. Having a checklist to mark when you use a source is also super nice."
      )}
      {/* Redundant for infinite scroll */}
      {renderQuote(
        "Chloe",
        "University of Windsor",
        "Finally something easier! Profs always try to sell Zotero as the easy option, but truth be told you need an above average tech knowledge to use it well."
      )}
      {renderQuote(
        "KYLE",
        "HEIDELBERG UNIVERSITY",
        "Very simple to use and beyond helpful!"
      )}
      {renderQuote(
        "Dana",
        "Arizona State University",
        "Opendemia is awesome! Honestly, I went to the website thinking it was going to be this complicated and expensive thing. I love the tutorial; it shows me how easy it is to use and why it’s different than say EasyBib."
      )}
      {renderQuote(
        "Sydney",
        "High School Student",
        "Very well organized I love how convenient it was! "
      )}
      {renderQuote(
        "Rebekah",
        "High School Student",
        "I love Opendemia. I thought it was very helpful in keeping my sources organized."
      )}
      {renderQuote(
        "Dylan",
        "The Ohio State University",
        "This is great for streamlining paper writing."
      )}
      {renderQuote(
        "Mason",
        "University of Toledo",
        "This expedited my paper writing process and I will definitly be sharing with my friends."
      )}
      {renderQuote(
        "Liz",
        "Clemson University",
        "I love that all sources and citations are put together in one place and that you can mark specific quotes within sources as used. Having a checklist to mark when you use a source is also super nice."
      )}
    </Carousel>
  );
}

export default QuoteCarousel;
