// Backend Service Base URL
export const baseURL = 'https://devo.demiaservice.com'

// Authentication Form Fields
export const formFields = {
    signIn: {
      username: {
        labelHidden: true,
        placeholder: 'Email',
        isRequired: true,
      },
    },
    signUp: {
      username: {
        labelHidden: true,
        placeholder: 'Email',
        isRequired: true,
      },
      name: {
        labelHidden: true,
        placeholder: 'Full Name',
        isRequired: true,
      }
    }
}

// Authentication theme
export const authTheme = {
    name: 'auth-theme',
    tokens: {
        colors: {
            background: {
              borderColor: { value: 'var(--opendemia-landing-grey)' },
              secondary: { value: 'var(--opendemia-green)' }
            }
        },
        components: {
          button: {
            primary: {
              backgroundColor: { value: 'var(--opendemia-green)' },
              _hover: {
                borderColor: { value: 'transparant' },
                color: { value: 'var(--opendemia-green)' }
              },
            }
          },
          tabs: {
            item: {
              _active: {
                backgroundColor: { value: 'transparant' },
                borderColor: { value: 'var(--opendemia-green)' },
                color: { value: 'var(--opendemia-green)' }
              },
              _focus: {
                backgroundColor: { value: 'transparant' },
                borderColor: { value: 'var(--opendemia-green)' },
                color: { value: 'var(--opendemia-green)' }
              }
            }
          },
          authenticator: {
            state: {
              _inactive: {
                backgroundColor: { value: 'transparant' },
                borderColor: { value: 'transparant' }
              }
            }
          }
        }
    }
}